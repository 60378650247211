import { UserRectangle } from '@phosphor-icons/react';
import React from 'react';
import { Stack, styled } from '@mui/material';
import { HubSpotFormProps, HubspotForm } from '@lib/components/SwFormBuilder/HubspotForm';
import { usePublicIp } from '@lib/components/SwFormBuilder/ip';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type ArticlFormProps = HubSpotFormProps & {
    slug: string;
    formName: string;
    locale: string;
};

const buildFormName = (locale: string, formName?: string) => `${formName} ${locale.toUpperCase()}`;

const Title = styled(SwTypography)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ArticleForm: React.FC<ArticlFormProps> = (props) => {
    const { form, id, slug, formName, locale, ...formProps } = props;
    const ip = usePublicIp();

    return (
        <SwPaper>
            <Stack spacing={2}>
                <Title bold={true} component={'p'} variant={'h5'}>
                    <UserRectangle size={20} /> Your details
                </Title>

                <HubspotForm
                    form={form}
                    id={id}
                    ipAddress={ip}
                    options={{ showSuccess: false }}
                    pageName={slug}
                    values={{ form_name: buildFormName(locale, formName) }}
                    {...formProps}
                />
            </Stack>
        </SwPaper>
    );
};
