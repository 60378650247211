import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { grey5 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { ArticleCoverVideoProps, ArticleCoverVideo } from './ArticleCoverVideo';

export type ArticleHeaderFullWidthProps = ArticleCoverVideoProps & {
    title: string;
    coverImage: {
        alt: string;
        gatsbyImageData: GatsbyImageProps['image'];
    };
};

const CoverImage = styled(GatsbyImage)`
    border-radius: 8px;
    aspect-ratio: 16/9;
    border: 1px solid ${grey5};
    overflow: hidden;
    will-change: transform;
    z-index: 0;
`;

const ArticleTitle = styled(SwTypography)`
    overflow-wrap: anywhere;
`;

export const ArticleHeaderFullWidth: React.FC<ArticleHeaderFullWidthProps> = (props) => {
    const { title, coverImage, ...videoCoverProps } = props;

    return (
        <SwContainer>
            <Grid container={true}>
                <Grid md={8} sm={12}>
                    <SwSpacer direction={'vertical'} spacing={4} />
                    <ArticleTitle component={'h1'} variant={'h3'}>
                        {title}
                    </ArticleTitle>
                    <SwSpacer direction={'vertical'} spacing={4} />
                </Grid>
                <SwSpacer direction={'vertical'} spacing={4} />
                {videoCoverProps.video ? (
                    <ArticleCoverVideo {...videoCoverProps} />
                ) : (
                    <CoverImage alt={coverImage.alt} image={coverImage.gatsbyImageData} style={{ width: '100%' }} />
                )}
                <SwLine color={grey5} direction={'horizontal'} spacing={4} />
            </Grid>
        </SwContainer>
    );
};
