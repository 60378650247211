import { Play } from '@phosphor-icons/react';
import React from 'react';
import VideoPlayer from 'react-player';
import { styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { GatsbyImageProps, GatsbyImage } from 'gatsby-plugin-image';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { AspectRatios } from '@lib/enums/apectRatios';

export type ArticleCoverVideoProps = {
    video: {
        externalUrl?: string;
        poster: {
            alt: string;
            gatsbyImageData: GatsbyImageProps['image'];
        };
    };
    isGated?: boolean;
};

const CoverImageContainer = styled('div')`
    border-radius: 8px;
    aspect-ratio: 16/9;
    border: 1px solid ${colors.border};
    overflow: hidden;
    will-change: transform;
    position: relative;
    z-index: 0;
    width: 100%;
`;

const Overlay = styled('div')`
    position: relative;
    z-index: 1;
    background: rgb(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
`;

const Pile = styled('div')`
    display: grid;

    & > * {
        grid-area: 1 / 1;
    }
`;

export const ArticleCoverVideo: React.FC<ArticleCoverVideoProps> = (props) => {
    const { video, isGated } = props;

    const { search } = useLocation();

    return (
        <CoverImageContainer>
            {isGated ? (
                <Pile>
                    <Overlay>
                        <SwInternalLink
                            aria-label={'Complete form to play the video'}
                            replace={false}
                            size={'large'}
                            style={{ aspectRatio: AspectRatios.square, borderRadius: 999, width: 70, height: 70 }}
                            to={`${search}#gated-form`}
                            variant={'secondary'}
                        >
                            <Play size={20} />
                        </SwInternalLink>
                    </Overlay>
                    <GatsbyImage
                        alt={video.poster.alt}
                        image={video.poster.gatsbyImageData}
                        objectFit={'cover'}
                        style={{ height: '100%', width: '100%' }}
                    />
                </Pile>
            ) : video.externalUrl ? (
                <VideoPlayer
                    config={{
                        vimeo: {
                            playerOptions: {
                                responsive: true,
                                height: 630
                            }
                        },
                        youtube: {
                            playerVars: {
                                height: 630
                            }
                        }
                    }}
                    controls={true}
                    height={'100%'}
                    style={{ position: 'absolute', inset: 0 }}
                    url={video.externalUrl}
                    width={'100%'}
                />
            ) : (
                <GatsbyImage
                    alt={video.poster.alt}
                    image={video.poster.gatsbyImageData}
                    objectFit={'cover'}
                    style={{ height: '100%', width: '100%' }}
                />
            )}
        </CoverImageContainer>
    );
};
