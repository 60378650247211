import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementNode } from '@graphcms/rich-text-types';
import { Box, useMediaQuery, useTheme, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContentLayout } from '@lib/components/SwContentLayout';
import { SwDownloadableCard } from '@lib/components/SwDownloadableCard';
import { SwGate } from '@lib/components/SwGate';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwSharingLinksSection } from '@lib/components/SwSharingLinksSection';
import { SwTableOfContents } from '@lib/components/SwTableOfContents';
import { grey3, grey5, grey6 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { ColorScheme, themeTokensMap } from '@lib/enums/ColorScheme';
import { ArticleForm } from '@app/articles/ArticleForm';
import { ArticleHeaderFullWidth } from '@app/articles/ArticleHeaderFullWidth';
import { ArticleHeaderTwoColumns } from '@app/articles/ArticleHeaderTwoColumns';
import { ArticleMeta } from '@app/articles/ArticleMeta';
import { SelectArticleVersion } from '@app/articles/SelectArticleVersion';
import { useGatedContent } from '@app/articles/useGatedContent';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const themeMap = {
    [ColorScheme.GREEN]: {
        name: ColorScheme.GREEN,
        contentTheme: {
            name: 'light-green',
            TitleCard: {
                backgroundColor: themeTokensMap[ColorScheme.GREEN].surface4,
                textMain: themeTokensMap[ColorScheme.GREEN].textSubdued,
                textSubdued: themeTokensMap[ColorScheme.GREEN].textSubdued
            }
        }
    },
    [ColorScheme.PINK]: {
        name: ColorScheme.PINK,
        contentTheme: {
            name: 'light-pink',
            TitleCard: {
                backgroundColor: themeTokensMap[ColorScheme.PINK].surface4,
                textMain: themeTokensMap[ColorScheme.PINK].textSubdued,
                textSubdued: themeTokensMap[ColorScheme.PINK].textSubdued
            }
        }
    },
    [ColorScheme.BLUE]: {
        name: ColorScheme.BLUE,
        contentTheme: {
            name: 'light-blue',
            TitleCard: {
                backgroundColor: themeTokensMap[ColorScheme.BLUE].surface4,
                textMain: themeTokensMap[ColorScheme.BLUE].textSubdued,
                textSubdued: themeTokensMap[ColorScheme.BLUE].textSubdued
            }
        }
    },
    [ColorScheme.YELLOW]: {
        name: ColorScheme.YELLOW,
        contentTheme: {
            name: 'light-yellow',
            TitleCard: {
                backgroundColor: themeTokensMap[ColorScheme.YELLOW].surface4,
                textMain: themeTokensMap[ColorScheme.YELLOW].textSubdued,
                textSubdued: themeTokensMap[ColorScheme.YELLOW].textSubdued
            }
        }
    }
};

const FORM_ID = 'gated-form';

const SidebarContainer = styled(SwFlexBox)`
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const ArticleTemplate = ({ data, pageContext: { slug } }) => {
    const article = data.graphCmsArticle;
    const availableLocalizations = data.articleLocalizations.nodes.filter(({ locale }) =>
        data.articleLocalizations.distinct.includes(locale)
    );

    const isTabletOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();
    const { isGated, contentToShow, handleAfterSubmit } = useGatedContent({
        id: article.id,
        locale: article.locale,
        formName: article.formName,
        content: article.content?.children ?? [],
        scrollTargetAfterSuccess: '#start'
    });

    const tableOfContentsJson = useMemo(
        () =>
            contentToShow
                .filter(({ __typename }) => ['GraphCMS_ContentCard', 'GraphCMS_TitleCard'].includes(__typename))
                .flatMap(({ __typename, ...rest }) => {
                    switch (__typename) {
                        case 'GraphCMS_ContentCard':
                            return Array.isArray(rest.content.json) ? rest.content.json : rest.content.json.children;
                        case 'GraphCMS_TitleCard':
                            return { type: 'heading-two', children: [{ text: rest.title }] } as ElementNode;
                    }
                }),
        [contentToShow]
    );

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
                localizedSlugs={availableLocalizations.map(({ locale, slug, category }) => ({
                    locale,
                    slug: `${WEBSITE_MENU_ITEM[category.usage.toLowerCase()].link}/${slug}`
                }))}
            />
            <SwLayout background={grey6}>
                <SwBreadcrumb
                    basePage={article.category.usage}
                    baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()].link}
                    page={article.category?.label}
                />
                {article.headerType === 'two_columns' ? (
                    <ArticleHeaderTwoColumns
                        abstract={article.abstract}
                        coverImage={article.coverImage}
                        industry={article.industry}
                        tags={article.tags}
                        title={article.title}
                    />
                ) : (
                    <ArticleHeaderFullWidth
                        coverImage={article.coverImage}
                        isGated={isGated}
                        title={article.title}
                        video={article.video}
                    />
                )}

                <Box display={'flex'} flexDirection={'column'}>
                    <SwContainer paddingInline={{ xs: '0px' }}>
                        <span
                            aria-hidden={'true'}
                            id={'start'}
                            style={{ transform: 'translateY(-32px)', display: 'inline-block' }}
                        />
                        <SwContentLayout
                            key={`content-${isGated ? 'free' : 'full'}`}
                            leftSide={
                                isTabletOrLess ? (
                                    <>
                                        <SidebarContainer flexDirection={'column'} gap={16}>
                                            <ArticleMeta article={article} collapsible={true} defaultExpanded={true} />
                                            <SwTableOfContents json={tableOfContentsJson} />
                                            {data.articleVersions.nodes.length > 1 && (
                                                <SelectArticleVersion
                                                    baseUrl={
                                                        WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()].link
                                                    }
                                                    currentVersion={article.industry?.label ?? 'All sectors'}
                                                    id={'book-versions-select'}
                                                    versionedContentsInfo={data.articleVersions.nodes?.map(
                                                        ({ industry, slug, locale }) => ({
                                                            slug,
                                                            label: industry?.label ?? 'All sectors',
                                                            locale
                                                        })
                                                    )}
                                                />
                                            )}
                                            {article.downloadable && (
                                                <SwDownloadableCard
                                                    asset={article.downloadable.asset}
                                                    buttonLabel={article.downloadable.buttonLabel}
                                                    disabled={isGated}
                                                    disabledHref={`#${FORM_ID}`}
                                                    title={article.downloadable.title}
                                                />
                                            )}
                                        </SidebarContainer>
                                    </>
                                ) : (
                                    <>
                                        <ArticleMeta article={article} collapsible={false} />
                                        <SwTableOfContents json={tableOfContentsJson} />
                                    </>
                                )
                            }
                            rightSide={
                                isTabletOrLess ? (
                                    <SidebarContainer>
                                        <SwSharingLinksSection
                                            abstract={article.abstract}
                                            slug={slug}
                                            title={article.title}
                                        />
                                    </SidebarContainer>
                                ) : (
                                    <>
                                        <SwSharingLinksSection
                                            abstract={article.abstract}
                                            slug={slug}
                                            title={article.title}
                                        />
                                        {data.articleVersions.nodes.length > 1 && (
                                            <SelectArticleVersion
                                                baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()].link}
                                                currentVersion={article.industry?.label ?? 'All sectors'}
                                                id={'book-versions-select'}
                                                versionedContentsInfo={data.articleVersions.nodes?.map(
                                                    ({ industry, slug, locale }) => ({
                                                        slug,
                                                        locale,
                                                        label: industry?.label ?? 'All sectors'
                                                    })
                                                )}
                                            />
                                        )}
                                        {article.downloadable && (
                                            <SwDownloadableCard
                                                asset={article.downloadable.asset}
                                                buttonLabel={article.downloadable.buttonLabel}
                                                disabled={isGated}
                                                disabledHref={`#${FORM_ID}`}
                                                title={article.downloadable.title}
                                            />
                                        )}
                                    </>
                                )
                            }
                        >
                            <SwCmsRenderer
                                content={contentToShow}
                                layout={'content'}
                                theme={themeMap[article.theme]?.contentTheme}
                            />
                            {isGated && article.formLayout === 'inline' ? (
                                <SwContentLayout.Main withPaddingOnMobile={true}>
                                    <ArticleForm
                                        form={data.form}
                                        formName={article.formName}
                                        id={FORM_ID}
                                        locale={article.locale}
                                        slug={article.slug}
                                        onSuccess={() => handleAfterSubmit()}
                                    />
                                </SwContentLayout.Main>
                            ) : null}
                        </SwContentLayout>
                    </SwContainer>
                    {isGated ? (
                        (!article.formLayout || article.formLayout === 'overlay') && (
                            <SwGate subtitle={t('gated_form_subtitle')} title={t('just_a_moment')}>
                                <ArticleForm
                                    form={data.form}
                                    formName={article.formName}
                                    id={FORM_ID}
                                    locale={article.locale}
                                    slug={article.slug}
                                    onSuccess={() => handleAfterSubmit()}
                                />
                            </SwGate>
                        )
                    ) : (
                        <Box display={'flex'} flexDirection={'column'}>
                            <SwContainer>
                                <SwLine color={grey5} spacing={4} />
                            </SwContainer>
                            <SwContainer gap={32} paddingBlock={'16px'}>
                                <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                    {t('more_stories')}
                                </SwTypography>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.moreArticles.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </Box>
                    )}
                </Box>
                <SwWebsiteFooter margin={isGated ? 0 : 4} newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        graphCmsArticle: { seo, coverImage }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url || coverImage.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const getArticleQuery = graphql`
    query getArticlesQuery(
        $id: String!
        $remoteId: ID!
        $usage: GraphCMS_ContentUsage
        $title: String
        $locale: GraphCMS_Locale
        $formName: String = "Gated Content"
    ) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        form: hubspotForm(name: { eq: $formName }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        articleLocalizations: allGraphCmsArticle(filter: { remoteId: { eq: $remoteId } }) {
            distinct(field: actualLocale)
            nodes {
                slug
                locale
                category {
                    usage
                }
            }
        }
        articleVersions: allGraphCmsArticle(
            filter: { category: { usage: { eq: $usage } }, title: { eq: $title }, seo: { noIndex: { ne: true } } }
        ) {
            distinct(field: industry___label)
            nodes {
                slug
                locale
                industry {
                    label
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        moreArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                category: { usage: { eq: $usage } }
                id: { ne: $id }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 3
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        graphCmsArticle(id: { eq: $id }, actualLocale: { eq: $locale }) {
            id
            category {
                label
                usage
            }
            abstract
            published
            title
            slug
            locale
            video {
                externalUrl
                poster {
                    alt
                    gatsbyImageData(aspectRatio: 1.678, layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
                }
            }
            theme
            formName
            headerType
            industry {
                label
            }
            downloadable {
                ...Essentials_GraphCMS_DownloadCard
            }
            formLayout
            tags {
                id: remoteId
                label
                color
            }
            content {
                children: remoteChildren {
                    ... on GraphCMS_ContentCard {
                        id
                        __typename
                        content {
                            json
                            references {
                                ...Essentials_GraphCMS_Asset
                                ...Essentials_GraphCMS_Quote
                                ...Essentials_GraphCMS_CustomerQuote
                                ...Essentials_GraphCMS_CallOut
                            }
                        }
                    }
                    ...Essentials_GraphCMS_FullWidthImage
                    ...Essentials_GraphCMS_TitleCard
                    ...Essentials_GraphCMS_CallOut
                }
            }
            authors {
                ...Essentials_GraphCMS_Person
            }
            reviewers {
                ...Essentials_GraphCMS_Person
            }
            coverImage {
                alt
                gatsbyImageData(aspectRatio: 1.678, layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
                url
            }
            seo {
                image {
                    url
                }
                noIndex
                title
                description
            }
        }
    }
`;

export default ArticleTemplate;
