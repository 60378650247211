import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { navigate } from 'gatsby';
import { grey3, grey7 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import { SwSelect, SwSelectOption } from '@lib/components/bedrock/form/SwSelect';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { slugifyName } from '@lib/helpers/object';

export type SelectArticleVersionProps = {
    id: string;
    baseUrl: string;
    currentVersion: string;
    versionedContentsInfo: Array<{
        slug: string;
        label: string;
        locale: string;
    }>;
};

const Container = styled(SwPaper)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const SelectArticleVersion: React.FC<SelectArticleVersionProps> = (props) => {
    const { currentVersion, versionedContentsInfo, baseUrl, id } = props;
    const { t } = useTranslation();

    const versionsOptions = versionedContentsInfo.map((version) => ({
        key: slugifyName(version.label),
        value: version.label
    }));

    const navigateToBook = (event) => {
        const targetedVersion = event.target.value;
        const version = versionedContentsInfo.find(({ label }) => slugifyName(label) === targetedVersion);

        if (version.slug) {
            navigate(`${version.locale === 'en' ? '' : `/${version.locale}`}${baseUrl}/${version.slug}`);
        }
    };

    if (versionsOptions.length <= 1) {
        return null;
    }

    return (
        <Container backgroundColor={grey7}>
            <SwTypography bold={true} color={grey3} component={'h2'} id={id} variant={'caption'}>
                {t('version')}
            </SwTypography>
            <SwSelect
                inputProps={{
                    name: 'version',
                    id: 'version',
                    'aria-labelledby': id
                }}
                value={slugifyName(currentVersion)}
                onChange={navigateToBook}
            >
                {versionsOptions.map(({ key, value }) => (
                    <SwSelectOption key={key} value={key}>
                        {value}
                    </SwSelectOption>
                ))}
            </SwSelect>
        </Container>
    );
};
