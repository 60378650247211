import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import SwMediaCard from '@lib/components/SwMediaCard';
import { grey3, grey5 } from '@lib/components/bedrock/SwColors';
import SwTag, { SwTagColors } from '@lib/components/bedrock/badges/SwTag';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type ArticleHeaderTwoColumnsProps = {
    title: string;
    abstract: string;
    industry: {
        label: string;
    };
    coverImage: {
        alt: string;
        gatsbyImageData: GatsbyImageProps['image'];
    };
    tags: Array<{ id: string; label: string; color: SwTagColors }>;
};

const Header = styled(SwFlexBox)`
    margin-block-start: 32px;
`;

const BookTitle = styled(SwTypography)`
    overflow-wrap: anywhere;
`;

export const ArticleHeaderTwoColumns: React.FC<ArticleHeaderTwoColumnsProps> = (props) => {
    const { tags, title, coverImage, abstract, industry } = props;

    return (
        <SwContainer>
            <Header flexDirection={'column'}>
                <SwMediaCard
                    background={'white'}
                    layout={'content-left'}
                    media={
                        <GatsbyImage
                            alt={coverImage.alt}
                            image={coverImage.gatsbyImageData}
                            objectFit={'cover'}
                            style={{ width: '100%', height: '100%', aspectRatio: '4 / 3' }}
                        />
                    }
                >
                    <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={16} justifyContent={'start'}>
                        <SwFlexBox alignItems={'center'} flexWrap={'wrap'} gap={8} justifyContent={'start'}>
                            {tags.length ? (
                                <SwTag color={tags[0].color} size={'small'}>
                                    {tags[0].label}
                                </SwTag>
                            ) : null}
                            {industry?.label && (
                                <SwTag color={'outline'} size={'small'}>
                                    {industry.label}
                                </SwTag>
                            )}
                        </SwFlexBox>
                        <BookTitle component={'h1'} variant={'h3'}>
                            {title}
                        </BookTitle>
                        <SwTypography color={grey3} component={'p'} variant={'h4'}>
                            {abstract}
                        </SwTypography>
                    </SwFlexBox>
                </SwMediaCard>
                <SwLine color={grey5} direction={'horizontal'} spacing={4} />
            </Header>
        </SwContainer>
    );
};
