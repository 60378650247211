import React from 'react';
import { styled } from '@mui/material';
import { zIndexes } from '@lib/enums/zIndexes';
import SwContainer from '../../components/v1/SwContainer';
import { grey3, grey7 } from './bedrock/SwColors';
import { SwPaper } from './bedrock/SwPaper';
import SwFlexBox from './bedrock/layout/SwFlexBox';
import SwTypography from './bedrock/typography/SwTypography';

export type SwGateProps = {
    children: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
};

const Gate = styled('div')`
    --_faded-block-block-size: 250px;

    z-index: ${zIndexes.MODAL};
    padding-inline: 0;
    margin-block-start: calc(-1 * var(--_faded-block-block-size));
`;

const GateFadedBlock = styled('div')`
    inline-size: 100%;
    translate: 0 10%;
    block-size: var(--_faded-block-block-size);
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);
`;

const GatePaper = styled(SwPaper)`
    padding-inline: 0;
    border-block-end: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 1;
`;

const GateContent = styled('div')`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 48px;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        grid-template-columns: 1fr;
    }
`;

export const SwGate: React.FC<SwGateProps> = (props) => {
    const { title, children, subtitle } = props;

    return (
        <Gate>
            <GateFadedBlock />
            <GatePaper backgroundColor={grey7} padding={4}>
                <SwContainer withMaxWidth={true}>
                    <GateContent>
                        <SwFlexBox flexDirection={'column'} gap={16}>
                            <SwTypography bold={true} component={'p'} variant={'h4'}>
                                {title}
                            </SwTypography>
                            <SwTypography color={grey3} component={'p'} variant={'body1'}>
                                {subtitle}
                            </SwTypography>
                        </SwFlexBox>
                        {children}
                    </GateContent>
                </SwContainer>
            </GatePaper>
        </Gate>
    );
};
