import { Download } from '@phosphor-icons/react';
import React from 'react';
import { styled } from '@mui/material';
import { grey3, grey7 } from '@lib/components/bedrock/SwColors';
import { SwPaper, SwPaperProps } from '@lib/components/bedrock/SwPaper';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type SwDownloadableCardProps = SwPaperProps<'div'> & {
    asset: {
        url: string;
        fileName: string;
    };
    disabled?: boolean;
    disabledHref?: string;
    title?: string;
    buttonLabel?: string;
};

const Container = styled(SwPaper)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SwDownloadableCard: React.FC<SwDownloadableCardProps> = (props) => {
    const { asset, disabled, disabledHref, title = 'Download', buttonLabel = 'PDF', ...rest } = props;

    return (
        <Container backgroundColor={grey7} {...rest}>
            <SwTypography bold={true} color={grey3} component={'h2'} id={'article-language'} variant={'caption'}>
                {title}
            </SwTypography>
            {disabled ? (
                <SwInternalLink href={disabledHref} style={{ justifyContent: 'start' }} variant={'secondary'}>
                    <Download /> {buttonLabel}
                </SwInternalLink>
            ) : (
                <SwInternalLink
                    download={asset.fileName}
                    href={asset.url}
                    style={{ justifyContent: 'start' }}
                    target={'_blank'}
                    variant={'secondary'}
                >
                    <Download /> {buttonLabel}
                </SwInternalLink>
            )}
        </Container>
    );
};
