import React from 'react';
import AvatarGroup from '@mui/lab/AvatarGroup/AvatarGroup';
import { styled } from '@mui/material';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { isDefined } from '../../lib/helpers/object';
import SwAvatar, { SwAvatarProps } from './SwAvatar';

interface SwAvatarGroupProps {
    members: any[];
    totalMembers?: number;
    maxAmount?: number;
    avatarProps?: Omit<SwAvatarProps, 'user' | 'hideName'>;
    dataTestId?: string;
    borderColor?: string;
}
const StyledAvatarGroup = styled(AvatarGroup)<{ diameter?: number; borderColor: string }>`
    & .MuiAvatar-root {
        ${({ diameter }) => (isDefined(diameter) ? `width: ${diameter}px; height: ${diameter}px;` : '')}
        font-family: 'MaisonNeue-Book';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        border: 2px solid ${({ borderColor }) => borderColor};
    }

    & .MuiAvatarGroup-avatar {
        border: none;
    }
`;

const SwAvatarGroup: React.FC<SwAvatarGroupProps> = ({
    maxAmount,
    members,
    totalMembers,
    avatarProps,
    dataTestId,
    borderColor = colors.border
}) => {
    return (
        <StyledAvatarGroup
            borderColor={borderColor}
            data-test-id={dataTestId}
            diameter={avatarProps?.diameter}
            max={maxAmount ?? 3}
            total={totalMembers}
        >
            {members.map((member, index) => (
                <SwAvatar hideName={true} index={index} key={index} user={member} {...avatarProps} />
            ))}
        </StyledAvatarGroup>
    );
};

export default SwAvatarGroup;
