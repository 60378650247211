import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Box } from '@mui/material';
import dayjs from 'dayjs';
import { SwCollapsibleSection, SwCollapsibleSectionProps } from '@lib/components/SwCollapsibleSection';
import { grey3, grey7 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import SwAvatarGroup from '../../components/v1/SwAvatarGroup';
import SwLink from '../../components/v1/SwLink';

export type ArticleMetaProps = Pick<SwCollapsibleSectionProps, 'defaultExpanded'> & {
    article: any;
    collapsible?: boolean;
};

const DescriptionList = styled('dl')`
    & > dd:not(:last-child) {
        padding-block-end: 8px;
    }
`;

const TagsContainer = styled(SwFlexBox)`
    margin-block-start: 4px;
`;

const Container = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const ArticleMetaContent: React.FC<Omit<ArticleMetaProps, 'collapsible' | 'defaultExpanded'>> = (props) => {
    const { article, ...rest } = props;

    const { t } = useTranslation();

    const members = [...new Set([...article.authors, ...article.reviewers])].map((person) => ({
        gatsbyImage: person.photo?.gatsbyImageData,
        firstName: person.firstName,
        lastName: person.lastName
    }));

    return (
        <Container {...rest}>
            <Box display={'flex'} justifyContent={'start'}>
                <SwAvatarGroup
                    avatarProps={{ diameter: 38 }}
                    borderColor={grey7}
                    maxAmount={3}
                    members={members}
                    totalMembers={members.length}
                />
            </Box>
            <DescriptionList>
                {article.authors.length ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            {t('author', { count: article.authors.length })}
                        </SwTypography>
                        {article.authors.map((author) => (
                            <SwTypography component={'dd'} display={'flex'} flexDirection={'column'} key={author.id}>
                                {author.profile ? (
                                    <SwLink
                                        href={`${WEBSITE_MENU_ITEM.profiles.link}/${author.profile.slug}`}
                                        noGatsbyLink={false}
                                        variant={'body2'}
                                        withI18n={false}
                                    >
                                        {author.firstName} {author.lastName}
                                    </SwLink>
                                ) : (
                                    `${author.firstName} ${author.lastName}`
                                )}
                                {author.role ? (
                                    <SwTypography color={grey3} component={'span'} variant={'caption'}>
                                        {author.role}
                                    </SwTypography>
                                ) : null}
                            </SwTypography>
                        ))}
                    </>
                ) : null}
                {article.reviewers.length ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            {t('reviewer', { count: article.reviewers.length })}
                        </SwTypography>
                        {article.reviewers.map((reviewer) => (
                            <SwTypography component={'dd'} display={'flex'} flexDirection={'column'} key={reviewer.id}>
                                {`${reviewer.firstName} ${reviewer.lastName}`}
                                {reviewer.role ? (
                                    <SwTypography color={grey3} component={'span'} variant={'caption'}>
                                        {reviewer.role}
                                    </SwTypography>
                                ) : null}
                            </SwTypography>
                        ))}
                    </>
                ) : null}
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('category')}
                </SwTypography>
                <SwTypography component={'dd'}>{article.category?.label}</SwTypography>
                {article.tags.length ? (
                    <>
                        <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                            {t('topics')}
                        </SwTypography>
                        <SwTypography component={'dd'}>
                            <TagsContainer flexDirection={'row'} flexWrap={'wrap'} gap={8}>
                                {article.tags.map((tag) => (
                                    <SwTag color={tag.color} key={tag.id} size={'small'} uppercase={true}>
                                        {tag.label}
                                    </SwTag>
                                ))}
                            </TagsContainer>
                        </SwTypography>
                    </>
                ) : null}
                <SwTypography bold={true} color={grey3} component={'dt'} variant={'caption'}>
                    {t('published')}
                </SwTypography>
                <SwTypography component={'dd'}>{dayjs(props.article.published).format('DD MMMM YYYY')}</SwTypography>
            </DescriptionList>
        </Container>
    );
};

export const ArticleMeta: React.FC<ArticleMetaProps> = (props) => {
    const { collapsible, defaultExpanded, ...rest } = props;
    const { t } = useTranslation();

    return collapsible ? (
        <SwCollapsibleSection
            defaultExpanded={defaultExpanded}
            title={
                <SwTypography bold={true} color={grey3} component={'h2'} variant={'caption'}>
                    {t('details')}
                </SwTypography>
            }
        >
            <ArticleMetaContent {...rest} />
        </SwCollapsibleSection>
    ) : (
        <SwPaper bgcolor={grey7}>
            <ArticleMetaContent {...rest} />
        </SwPaper>
    );
};
