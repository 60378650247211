import { useMemo } from 'react';
import { navigate } from 'gatsby';
import { useLocalStorageGlobalState } from '@lib/hooks/useLocalStorageGlobalState';
import { isBrowser } from '@app/common/helpers/gatsby';

export type UseGatedContentArgs = {
    id: string;
    formName?: string;
    locale: string;
    content: Array<any>;
    scrollTargetAfterSuccess?: 'top' | `#start`;
};

export const useGatedContent = ({
    content,
    id,
    formName,
    scrollTargetAfterSuccess = '#start'
}: UseGatedContentArgs) => {
    const [isGated, setGated, removeGateInLocalStorage] = useLocalStorageGlobalState({
        key: `${id}-gate`,
        initialValue: Boolean(formName)
    });

    const contentToShow = useMemo(() => {
        // If we think this is a Google request.
        const isGoogle = isBrowser() && navigator?.userAgent.toLowerCase().includes('googlebot');

        if (isGoogle || !isGated) {
            return content;
        } else if (isGated) {
            const secondContentCardIndex = content.findIndex(({ __typename }) => __typename === 'GraphCMS_ContentCard');

            return [...content].slice(0, secondContentCardIndex + 1);
        }
    }, [isGated, content]);

    const handleAfterSubmit = () => {
        setGated(false);
        scrollTargetAfterSuccess === 'top'
            ? window.scrollTo(0, 0)
            : navigate(scrollTargetAfterSuccess, { replace: false });
    };

    if (isBrowser() && isGated && !formName) {
        removeGateInLocalStorage();
    }

    return {
        isGated,
        contentToShow,
        setGated,
        handleAfterSubmit
    };
};
